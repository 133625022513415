import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import CustomerStructure from 'components/Structure/customer'
import MainHeader from "components/header"
import "components/layout.scss"

const headerNavigation = [
  {text: "Sitemap", link: "/customer/sitemap"},
  {text: "Contact Support", link: "mailto:support@kindful.com"}
]

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteCustomerQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
      <MainHeader logoLink="./" nav={headerNavigation}/>
      <CustomerStructure>
        {children}
      </CustomerStructure>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

export const customerNav = [
  {
    "GETTING STARTED GUIDE": [
      {text: "Overview", link: "/customer"},
      {text: "Get to know us", link: "/customer/get-to-know-us"},
      {text: "Gain API access", link: "/customer/gain-api-access"},
      {text: "Create a custom app", link: "/customer/create-a-custom-app"},
      {text: "- Production Dashboard Options", link: "/customer/production-dashboard-options"},
      {text: "- Sandbox Dashboard Options", link: "/customer/sandbox-dashboard-options"},
      {text: "Set up your environment", link: "/customer/set-up-your-environment"},
      {text: "API Standards", link: "/customer/api-standards"},
      {text: "Explore the docs", link: "/customer/explore-the-docs"},
      {text: "Check out our resources", link: "/customer/check-out-our-resources"},
      {text: "Sandbox API Reference", link: "https://documenter.getpostman.com/view/7005508/S1EWQFhd"},
      {text: "Production API Reference", link: "https://documenter.getpostman.com/view/7005508/S1EWQbBY"},
    ]
  },
  {
    "IMPORTING GUIDE": [
      {text: "Introduction to Imports", link: "/customer/importing-guide/intro-to-imports"},
      {text: "Record Matching Overview", link: "/customer/importing-guide/record-matching-overview"},
      {text: "Record Matching Options:", link: ""},
      {text: "- Contacts", link: "/customer/importing-guide/contacts"},
      {text: "- Transactions", link: "/customer/importing-guide/transactions"},
      {text: "- Funds", link: "/customer/importing-guide/funds"},
      {text: "- Assign a fund to a transaction", link: "/customer/importing-guide/assign-a-fund-to-a-transaction"},
      {text: "- Custom Fields", link: "/customer/importing-guide/custom-fields"},
      {text: "- Group Matching", link: "/customer/importing-guide/group-matching"},
      {text: "- Campaign Records", link: "/customer/importing-guide/campaign-records"},
      {text: "- Event Records", link: "/customer/importing-guide/event-records"},
    ]
  },
  {
    "Linking Guide": [
      {text: "Contact Link", link: "/customer/linking-guide/contact-link"},
      {text: "Transaction Link", link: "/customer/linking-guide/transaction-link"},
      {text: "2-way Sync", link: "/customer/linking-guide/2-way-sync"}
    ]
  },
  {
    "Querying Guide": [
      {text: "Contact Queries", link: "/customer/querying-guide/contact-queries"},
      {text: "Transaction Queries", link: "/customer/querying-guide/transaction-queries"},
      {text: "Query for Metadata", link: "/customer/querying-guide/query-for-metadata"},
    ]
  },
  {
    "Registrations Guide": [
      {text: "Creating", link: "/customer/registrations-guide/creating"},
      {text: "Retrieving Form Fields", link: "/customer/registrations-guide/retrieving-form-fields"},
    ]
  },
  {
    "Custom Donation Flow Guide": [
      {text: "Presets for Hosting Donation Pages", link: "/customer/custom-donation-flow-guide/presets-for-hosting-donation-pages"},
      {text: "Localization & Language Support", link: "/customer/custom-donation-flow-guide/localization-and-language-support"},
      {text: "Create a custom checkout", link: ""},
      {text: "- Cart with a Single Donation Value", link: "/customer/custom-donation-flow-guide/cart-with-a-single-donation-value"},
      {text: "- (RECOMMENDED) Cart of Products", link: "/customer/custom-donation-flow-guide/cart-of-products"},
      {text: "- Setting Up Pledges", link: "/customer/custom-donation-flow-guide/setting-up-pledges"},
      {text: "- Creating a Custom Checkout", link: "/customer/custom-donation-flow-guide/creating-a-custom-checkout-on-kindful-donation-pages"}
    ]
  },
  {
    "Advanced": [
      {text: "GPG Encryption Key", link: "/customer/advanced/gpg-encryption-key"},
      {text: "Use Kindful for OAuth/SSO", link: "/customer/advanced/use-kindful-for-oauth-sso"},
    ]
  },
  {
    "Reference Guide": [
      {text: "Campaigns", link: "/customer/reference/campaigns"},
      {text: "Contact", link: "/customer/reference/contact"},
      {text: "Contact with Transaction", link: "/customer/reference/contact_with_transaction"},
      {text: "Contact with Cause Team", link: "/customer/reference/contact_with_cause_team"},
      {text: "Contact with Non-Cash Gift", link: "/customer/reference/contact_with_non_cash_gift"},
      {text: "Contact with Note", link: "/customer/reference/contact_with_note"},
      {text: "Contact with Pledge", link: "/customer/reference/contact_with_pledge"},
      {text: "Contact with Soft Credit", link: "/customer/reference/contact_with_soft_credit"},
      {text: "Contact Queries", link: "/customer/querying-guide/contact-queries"},
      {text: "Contact Link", link: "/customer/linking-guide/contact-link"},
      {text: "Contact Email Exist", link: "/customer/reference/contact_email_exist"},
      {text: "Custom Fields", link: "/customer/reference/custom_fields"},
      {text: "Custom Fields Groups", link: "/customer/reference/custom_fields_groups"},
      {text: "Details", link: "/customer/reference/details"},
      {text: "Funds", link: "/customer/reference/funds"},
      {text: "Groups", link: "/customer/reference/groups"},
      {text: "Import Additional Metadata", link: "/customer/reference/import_additional_metadata"},
      {text: "Import Status by Job ID", link: "/customer/reference/import_status_job_id"},
      {text: "Integrations Status", link: "/customer/reference/integrations_status"},
      {text: "Public Cause Data", link: "/customer/reference/public_cause_data"},
      {text: "Public Cause Team Data", link: "/customer/reference/public_cause_team_data"},
      {text: "Public Campaign Data", link: "/customer/reference/public_campaign_data"},
      {text: "Split Transaction", link: "/customer/reference/split_transaction"},
      {text: "Sync Status", link: "/customer/reference/sync_status"},
      {text: "Transaction Queries", link: "/customer/querying-guide/transaction-queries"},
      {text: "Transaction Link", link: "/customer/linking-guide/transaction-link"},
      {text: "Verify Event", link: "/customer/reference/verify_event"},
    ]
  },
]

export const partnerNav = [
  {
    "GETTING STARTED GUIDE": [
      {text: "Overview", link: "/partner"},
      {text: "Get to know us", link: "/partner/get-to-know-us"},
      {text: "Develop your idea", link: "/partner/develop-your-idea"},
      {text: "Scenarios to Consider", link: ""},
      {text: "- Import contacts into Kindful", link: "/partner/import-contacts-into-kindful"},
      {text: "- Import contacts with associated data", link: "/partner/import-contacts-into-kindful-with-associated-data"},
      {text: "- Retrieve and import contacts", link: "/partner/retrieve-contacts-from-kindful-and-import-them-into-your-application"},
      {text: "- Build a 2-way sync with Kindful", link: "/partner/build-a-2-way-sync-with-kindful"},
      {text: "- Leverage the Kindful fundraising API", link: "/partner/leverage-the-kindful-public-fundraising-api"},
      {text: "- Webhooks", link: "/partner/webhooks"},
      {text: "Partner Dashboard", link: ""},
      {text: "- Sign up for a Partner Account", link: "/partner/sign-up-for-a-partner-account"},
      {text: "- Partner Dashboard FAQ", link: "/partner/partner-dashboard-faq"},
      {text: "Set up your environment", link: "/partner/set-up-your-environment"},
      {text: "API Standards", link: "/partner/api-standards"},
      {text: "Explore the docs", link: "/partner/explore-the-docs"},
      // {text: "Create your tech design", link: "/partner/create-your-tech-design"},
      {text: "Submit app to Kindful", link: "/partner/submit-app-to-kindful"},
      {text: "Check out our resources", link: "/partner/check-out-our-resources"},
      {text: "Playground API Reference", link: "https://documenter.getpostman.com/view/7005508/S1EWQb2g#intro"},
      {text: "Production API Reference", link: "https://documenter.getpostman.com/view/7005508/S1EWQb7C"},
    ]
  },
  {
    "IMPORTING GUIDE": [
      {text: "Introduction to Imports", link: "/partner/importing-guide/intro-to-imports"},
      {text: "Record Matching Overview", link: "/partner/importing-guide/record-matching-overview"},
      {text: "Record Matching Options:", link: ""},
      {text: "- Contacts", link: "/partner/importing-guide/contacts"},
      {text: "- Transactions", link: "/partner/importing-guide/transactions"},
      {text: "- Funds", link: "/partner/importing-guide/funds"},
      {text: "- Assign a fund to a transaction", link: "/partner/importing-guide/assign-a-fund-to-a-transaction"},
      {text: "- Custom Fields", link: "/partner/importing-guide/custom-fields"},
      {text: "- Group Matching", link: "/partner/importing-guide/group-matching"},
      {text: "- Campaign Records", link: "/partner/importing-guide/campaign-records"},
      {text: "- Event Records", link: "/partner/importing-guide/event-records"},
    ]
  },
  {
    "Linking Guide": [
      {text: "Contact Link", link: "/partner/linking-guide/contact-link"},
      {text: "Transaction Link", link: "/partner/linking-guide/transaction-link"},
      {text: "2-way Sync", link: "/partner/linking-guide/2-way-sync"}
    ]
  },
  {
    "Querying Guide": [
      {text: "Contact Queries", link: "/partner/querying-guide/contact-queries"},
      {text: "Transaction Queries", link: "/partner/querying-guide/transaction-queries"},
      {text: "Query for Metadata", link: "/partner/querying-guide/query-for-metadata"},
    ]
  },
  {
    "Advanced": [
      {text: "GPG Encryption Key", link: "/partner/advanced/gpg-encryption-key"},
      {text: "Use Kindful for OAuth/SSO", link: "/partner/advanced/use-kindful-for-oauth-sso"},
    ]
  },
  {
    "Reference Guide": [
      // {text: "Campaigns", link: "/partner/reference/campaigns"},
      {text: "Contact", link: "/partner/reference/contact"},
      {text: "Contact with Transaction", link: "/partner/reference/contact_with_transaction"},
      {text: "Contact with Cause Team", link: "/partner/reference/contact_with_cause_team"},
      {text: "Contact with Non-Cash Gift", link: "/partner/reference/contact_with_non_cash_gift"},
      {text: "Contact with Note", link: "/partner/reference/contact_with_note"},
      {text: "Contact with Pledge", link: "/partner/reference/contact_with_pledge"},
      {text: "Contact with Soft Credit", link: "/partner/reference/contact_with_soft_credit"},
      {text: "Contact Queries", link: "/partner/querying-guide/contact-queries"},
      {text: "Contact Link", link: "/partner/linking-guide/contact-link"},
      // {text: "contacts/email_exist", link: "/partner/reference/contact_email_exist"},
      // {text: "Custom Fields", link: "/partner/reference/custom_fields"},
      // {text: "Custom Fields Groups", link: "/partner/reference/custom_fields_groups"},
      // {text: "Details", link: "/partner/reference/details"},
      // {text: "Funds", link: "/partner/reference/funds"},
      // {text: "Groups", link: "/partner/reference/groups"},
      {text: "Import Additional Metadata", link: "/customer/reference/import_additional_metadata"},
      {text: "Import Status by Job ID", link: "/partner/reference/import_status_job_id"},
      // {text: "Integrations Status", link: "/partner/reference/integrations_status"},
      // {text: "Public Cause Data", link: "/partner/reference/public_cause_data"},
      // {text: "Public Cause Team Data", link: "/partner/reference/public_cause_team_data"},
      // {text: "Public Campaign Data", link: "/partner/reference/public_campaign_data"},
      {text: "Split Transaction", link: "/partner/reference/split_transaction"},
      // {text: "Sync Status", link: "/partner/reference/sync_status"},
      {text: "Transaction Queries", link: "/partner/querying-guide/transaction-queries"},
      {text: "Transaction Link", link: "/partner/linking-guide/transaction-link"},
      // {text: "Verify Event", link: "/partner/reference/verify_event"},
    ]
  },
]
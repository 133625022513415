import React from 'react'
import PropTypes from 'prop-types'
import CustomerSidebar from '../Sidebar/customer'
import Footer from 'components/Footer'


const Structure = ({ children }) => (
  <>
  <div className="templateStyle">
    <div className="content">
    <div className="hide-on-mobile"><CustomerSidebar /></div>
      <div className="main">
        <div className="page">
        {children}
        </div>
      </div>
    </div>
  </div>
  </>
)
Structure.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
}
export default Structure
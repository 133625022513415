import React, { Component } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet';
import CustomerLayout from 'layout/CustomerLayout'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { FaChevronRight } from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';

class Template extends Component {
  render() {
    const { markdownRemark: page } = this.props.data;

    const breadcrumbs = () => {
      if(page.frontmatter.path === "/customer/") {
        return(
          <div>
            <Breadcrumb tag="nav" listTag="div">
            <BreadcrumbItem>Customer API Docs Home</BreadcrumbItem>
            </Breadcrumb>
        </div>
        )
      } else {
        return (
          <div>
            <Breadcrumb tag="nav" listTag="div">
            <BreadcrumbItem tag="a" href="/">Customer API Docs Home</BreadcrumbItem>
            <BreadcrumbItem>{page.frontmatter.title}</BreadcrumbItem>
            </Breadcrumb>
        </div>
        )
      }
    }

    const nextup = () => {
      if(page.frontmatter.nextTitle){
        return (
          <Breadcrumb tag="nav" listTag="div">
        <span className="up-next-copy"><FaChevronRight /> Up Next: </span><BreadcrumbItem tag="a" href={page.frontmatter.nextURL}>{page.frontmatter.nextTitle}</BreadcrumbItem>
      </Breadcrumb>
        )
      }
    }
    
    return (
      <CustomerLayout>
        {breadcrumbs()}
        <Helmet title={`Docs | ${page.frontmatter.title}`} />
        <header>
          <h1>{page.frontmatter.title}</h1>
          <span>{page.frontmatter.baseline}</span>
        </header>
        
        <div dangerouslySetInnerHTML={{ __html: page.html }} />
      
        <div className="up-next-div">
          <hr />
          {nextup()}
        </div>
      </CustomerLayout>
    );
  }
}
export default Template
export const pageQuery = graphql`
  query CustomerDocsByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        nextTitle
        nextURL
      }
    }
  }
`
;
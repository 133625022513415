import React from 'react'
import { Col, Row } from 'reactstrap';
import cloud from 'images/Cloud.svg'

const Footer = () => {
 return <footer>
  <Row>
    <Col sm="4">
      <img className="cloud" src={cloud} alt="Kindful Cloud" />    
    </Col>
    <Col sm="2">
    <h6>FEATURES</h6>
    <ul> 
      <li><a href="https://www.kindful.com/raise-money-online/">Raise Money Online</a></li>
      <li><a href="https://www.kindful.com/track-your-donors/">Track Your Donors</a></li>
      <li><a href="https://www.kindful.com/analyze-donor-interactions/">Analyze Donor Interactions</a></li>
      <li><a href="https://www.kindful.com/integrations/">Integrations</a></li>
      <li><a href="https://www.kindful.com/feature-index/">Feature Index</a></li>
    </ul>
    </Col>
    <Col sm="2">
    <h6>Resources</h6>
    <ul> 
      <li><a href="https://www.kindful.com/resources/resource-guides/">Resource Guides</a></li>
      <li><a href="https://www.kindful.com/resources/customer-stories/">Customer Stories</a></li>
      <li><a href="https://www.kindful.com/resources/webinars/">Webinars</a></li>
      <li><a href="https://support.kindful.com/">Support</a></li>
      <li><a href="https://www.kindful.com/blog/">Kindful Blog</a></li>
    </ul>
    </Col>
    <Col sm="2">
    <h6>Partners</h6>
    <ul> 
      <li><a href="http://info.kindful.com/referrals">Referrals</a></li>
      <li><a href="https://developer.kindful.com/">Developers</a></li>
      <li><a href="https://www.kindful.com/partners/">Partners</a></li>
      <li><a href="https://www.kindful.com/partners/solutions/">Solutions</a></li>
      <li><a href="https://www.kindful.com/partners/affiliates/">Affiliates</a></li>
      <li><a href="http://info.kindful.com/partner-lead-registration">Lead Registration</a></li>
    </ul>
    </Col>
    <Col sm="2">
    <h6>Company</h6>
    <ul> 
      <li><a href="https://www.kindful.com/about/">About Kindful</a></li>
      <li><a href="https://www.kindful.com/careers/">Careers</a></li>
      <li><a href="https://www.kindful.com/tos/">Terms of Use</a></li>
      <li><a href="https://www.kindful.com/privacy/">Privacy</a></li>
      <li><a href="https://www.kindful.com/kindful-api-terms-and-conditions/">API Terms of Use</a></li>
    </ul>
    </Col>
  </Row>

  <div class="copyright-notice">© {new Date().getFullYear()}, <a href="https://www.kindful.com">Kindful</a>. All rights reserved.</div>

</footer>
}

export default Footer
import React from 'react'
import Link from 'gatsby-link'
import { customerNav } from './navigationLinks'

const links = (section, i) => {
  return customerNav[i][Object.keys(section)].map((item, i)=>{    
    if(item.link !== "" && !item.link.includes(":")){
      return ( <li key={i}><Link to={item.link}>{item.text}</Link></li> )
    } else if ( item.link.includes(":") ) {
      return ( <li key={i}><a href={item.link} target="_blank" rel="noopener noreferrer">{item.text}</a></li>)
    }else {
      return ( <li key={i} className="subheading"><b>{item.text}</b></li> )
    }
    
  })
}

const Menu = () => {
  return customerNav.map((item, i)=>{
    const section = Object.keys(item)
    return (
      <div key={i}>
        <header>{section}</header>
        <ul className="list-style-none">
          {links(customerNav[i], i)}
        </ul>
      </div>
    )
  })
}

const Sidebar = () => (
  <div className="sidebarContainer">
    <Menu />
  </div>
)
export default Sidebar
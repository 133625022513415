import React from "react"
import logo from 'images/logo.png'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';


export default class MainHeader extends React.Component {
    state = {
        isOpen: false,
        location: ""
      };


      componentDidMount(){
        console.log('location', document.location);

        this.setState({
          location: document.location.pathname
        })
      }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  navigation = () => {
    return this.props.nav.map((item, i)=>{
      return(
        <NavItem key={i}>
          <NavLink href={item.link} target={item.target || "_self"}>{item.text}</NavLink>
        </NavItem>
      )
    })
  }

  render() {
    const location = () => {
      const mainDirectory = this.state.location.split("/")
      
      if (mainDirectory.includes("customer")){
          return(
            <>
             <DropdownToggle nav caret>
                  Postman Reference
                </DropdownToggle>
                <DropdownMenu right>
              <div className="dropdown-heading">Customer API</div>
              <DropdownItem href="https://documenter.getpostman.com/view/7005508/S1EWQFhd"  target="_blank" rel="noopener noreferrer">
                Sandbox
              </DropdownItem>
              <DropdownItem href="https://documenter.getpostman.com/view/7005508/S1EWQbBY"  target="_blank" rel="noopener noreferrer">
                Production
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="/">
                    API Docs Home
                  </DropdownItem>
                </DropdownMenu>
            </>
          ) 
      }else if (mainDirectory.includes("partner")){
        return(
          <>
          <DropdownToggle nav caret>
                Postman Reference
                </DropdownToggle>
                <DropdownMenu right>
            <div className="dropdown-heading">Partner API</div>
            <DropdownItem href="https://documenter.getpostman.com/view/7005508/S1EWQb2g#intro"  target="_blank" rel="noopener noreferrer">
              Playground
            </DropdownItem>
            <DropdownItem href="https://documenter.getpostman.com/view/7005508/S1EWQb7C" target="_blank" rel="noopener noreferrer">
              Production
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem href="/">
                    API Docs Home
                  </DropdownItem>
                </DropdownMenu>
          </>
        )
      }
    }

    return (
      <div>
        <Navbar color="light" light expand="md">
          <NavbarBrand href={this.props.logoLink || "./"}>
              <img className="headerLogo" src={this.props.logo || logo} alt="Kindful Logo" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {this.navigation()}
              <UncontrolledDropdown nav inNavbar>
               
                  {location()}
                  
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
